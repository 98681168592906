/**
 * Libraries.
 */
function forEach(array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i])
    }
}

/**
 * Open and Close Newsletter when keydown A.
*/
const newsletter = document.querySelector('.modal-newsletter');

function openNewsletter() {
    const buttonOpenNewsletter = document.querySelector('.js-open-newsletter-modal')
    const buttonOpenNewsletterMobile = document.querySelector('.js-open-newsletter-modal-mobile');

    buttonOpenNewsletter.addEventListener('click', function () {
        newsletter.classList.add('is-active')
    });

    // buttonOpenNewsletterMobile.addEventListener('click', function () {
    //     newsletter.classList.add('is-active')
    // });

    // document.addEventListener('keydown', function (event) {
    //     if (event.ctrlKey && event.key === 'a') {
    //         newsletter.classList.add('is-active')
    //     }
    // });
}

function closeNewsletter() {
    const buttonCloseNewsletter = document.querySelector('.js-close-newsletter-modal');

    buttonCloseNewsletter.addEventListener('click', function () {
        newsletter.classList.remove('is-active')
    });

    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            newsletter.classList.remove('is-active')
        }
    });
}


/**
 * FAQ.
 */
const questions = document.querySelectorAll(".hubble-faq-question-header");

forEach(questions, (index, question) => {
    const content = question.nextElementSibling;
    const contentWrapper = document.querySelector(
        ".hubble-faq-question-content-wrapper"
    );

    question.addEventListener("click", function () {
        if (question.classList.contains("is-active")) {
            question.classList.remove("is-active");

            content.style.height = 0;
        } else {
            question.classList.add("is-active");

            content.style.height = `${contentWrapper.clientHeight}px`;
        }
    });
});

/**
 * Scroll Go To.
 */
// $(".js-scroll").click(function (e) {
//     var destination = $(this).attr('href');
//     e.preventDefault();

//     $('html, body').animate({
//         scrollTop: $(destination).offset().top - 70
//     }, 1500);
// });


/**
 * Config.
*/

// Newsletter .
if (newsletter) {
    openNewsletter();
    closeNewsletter();
}